import { Dispatch, SetStateAction, useState } from 'react'
import { useTheme } from 'styled-components'
import {
  PieChartOutlined,
  TagsOutlined,
  LogoutOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  TeamOutlined,
  NotificationOutlined,
  DollarOutlined,
  CommentOutlined,
  BankOutlined,
  ThunderboltOutlined,
  SnippetsOutlined,
  UnorderedListOutlined,
  SyncOutlined,
  PartitionOutlined,
  AlertOutlined,
  DatabaseOutlined,
  FolderOpenOutlined,
  IdcardOutlined,
  GoldOutlined,
  ProfileOutlined,
  WalletOutlined
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useWindowResize from '@hooks/useWindowResize'
import type { MenuProps } from 'antd'
import { IMenu } from '@/types/IMenu'
import { Menu, Button } from 'antd'
import { translateMenu } from '@helpers/translateMenu'
type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

interface AsideMenuProps {
  data: IMenu[]
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
}

interface LogoutButtonProps {
  collapsed: boolean
}

const Icons = {
  Dashboard: <PieChartOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Chamados: <TagsOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Alertas: <InfoCircleOutlined style={{ color: '#fff', fontSize: 20 }} />,
  RH: <NotificationOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Cotações: <DollarOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Atendimento: <CommentOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Financeiro: <BankOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Autofill: <ThunderboltOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Serviços: <SnippetsOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Listar: <UnorderedListOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Backups: <SyncOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Clientes: <IdcardOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Servidores: <DatabaseOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Setores: <TeamOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Tiers: <PartitionOutlined style={{ color: '#fff', fontSize: 20 }} />,
  'Status Geral': <AlertOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Artigos: <FolderOpenOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Níveis: <GoldOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Categorias: <ProfileOutlined style={{ color: '#fff', fontSize: 20 }} />,
  Extratos: <WalletOutlined style={{ color: '#fff', fontSize: 20 }} />
}

const handleMenuResponse = (data: IMenu[]) => {
  return data.map(item => {
    const subMenuItems = handleSubMenuResponse(item)
    const listItem = getItem(
      item.name,
      subMenuItems.length > 0 ? item.name : item.link,
      Icons[item.name as keyof typeof Icons],
      subMenuItems.length > 0 ? [...subMenuItems] : undefined
    )
    return listItem
  })
}

const handleSubMenuResponse = (item: IMenu) => {
  const subMenuItems = item.children.map(child =>
    getItem(child.name, child.link, Icons[child.name as keyof typeof Icons])
  )
  if (subMenuItems.length > 0) {
    subMenuItems.unshift(
      getItem('Listar', item.link, Icons['Listar' as keyof typeof Icons])
    )
  }
  return subMenuItems
}

const AsideHead = ({ setCollapsed }: Pick<AsideMenuProps, 'setCollapsed'>) => {
  const { width } = useWindowResize()
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src="/assets/images/logo-only-white.png" style={{ width: 70 }} />
      {width < 720 ? (
        <Button
          data-cy="aside-button-mobile-close"
          style={{ position: 'absolute', right: 10, top: 10 }}
          type="text"
          icon={<CloseOutlined style={{ color: 'white' }} size={20} />}
          onClick={() => setCollapsed(true)}
        />
      ) : null}
    </div>
  )
}

const LogoutButton = ({ collapsed }: LogoutButtonProps) => {
  const { width } = useWindowResize()
  const { t } = useTranslation()
  return (
    <a href="/logout">
      <Button
        data-cy="logout-menu"
        block
        style={{
          color: '#fff',
          position: 'absolute',
          display: collapsed && width < 720 ? 'none' : 'flex',
          bottom: 20,
          left: 0,
          right: 0
        }}
        type="link"
        icon={<LogoutOutlined color="#fff" />}
      >
        {!collapsed ? t('LOGOUT') : null}
      </Button>
    </a>
  )
}

const AsideMenu = ({
  data,
  collapsed,
  setCollapsed,
}: AsideMenuProps) => {
  const { width } = useWindowResize()
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const items = translateMenu(handleMenuResponse(data), t)
  const navigateItems = (e: { key: string }) => {
    navigate(e.key)
    width < 720 && setCollapsed(true)
  }

  return (
    <>
      <AsideHead setCollapsed={setCollapsed} />
      <Menu
        theme="dark"
        style={{
          backgroundColor: theme.blue,
          color: theme.white
        }}
        defaultSelectedKeys={['1']}
        mode="inline"
        items={items}
        data-cy="aside-menu-list"
        onClick={navigateItems}
      />
      <LogoutButton collapsed={collapsed} />
    </>
  )
}

export default AsideMenu

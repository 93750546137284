import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from 'antd'
import ModalTicket from '../Modal'
import Icon from '@components/common/Icon'
import { ITicket } from '@/types/ITicket'
import IconButton from '@components/common/IconButton'
import { useDrawerContext } from '@contexts/DrawerContext'
import TicketDrawer from '../TicketDrawer'

const ShowActions = ({ data }: { data: ITicket }) => {
  const [ticket, setTicket] = useState<ITicket>(data)
  useEffect(() => {
    if (data && data.updated_at !== ticket.updated_at) setTicket(data)
  }, [data])
  return (
    <TicketDrawer ticket={ticket}>
      <ShowActionsItems data={data} />
    </TicketDrawer>
  )
}

const ShowActionsItems = ({ data }: { data: ITicket }) => {
  const { open } = useDrawerContext(`/ticket/${data.tkid}`, data)
  const { t } = useTranslation()
  return (
    <Space size={4}>
      <IconButton
        name="fa-light fa-plus"
        describe={t('OPENTICKET')}
        data-cy="table-open-ticket"
        onClick={open}
      />
      {data.status !== 'CLOSED' ?
        <ModalTicket
          action="edit"
          icon={<Icon name="fa-light fa-pen" color="icon" />}
        /> : null}
    </Space>
  )
}

export default ShowActions

import { PropsWithChildren, useState, useMemo } from 'react'
import { OrderContext } from '@contexts/OrderContext'
import FormOrder from '../Form'
import FormHead from '../FormHead'
import { Drawer } from 'antd'
import Order, { OrderHead } from '@pages/Order'
import useModal from '@hooks/useModal'
import type { IOrder } from '@/types/IOrder'

interface OrderDrawerProps extends PropsWithChildren {
  order?: IOrder
  action: 'show' | 'edit' | 'create'
  numForm: string
}

const OrderDrawer = ({
  order,
  children,
  action,
  numForm
}: OrderDrawerProps) => {
  const [data, setData] = useState(order)
  const [price, setPrice] = useState(
    typeof order?.price_total === 'string' ? parseFloat(order?.price_total) : 0
  )
  const { open, onClose, onOpen } = useModal()
  const Component = action === 'show' ? Order : FormOrder
  const Head = action === 'show' ? OrderHead : FormHead
  const openDrawer = (order: IOrder) => {
    setData(order)
    onOpen()
  }
  const memoOpen = useMemo(
    () => ({
      data,
      setData,
      action,
      numForm,
      open,
      onClose,
      openDrawer,
      price,
      setPrice
    }),
    [data, open, onClose, onOpen, openDrawer, numForm]
  )

  return (
    <OrderContext.Provider value={memoOpen}>
      <Drawer
        title={<Head data={data} />}
        destroyOnClose
        width={'100vw'}
        {...{ ...{ open, onClose, onOpen } }}
      >
        <Component data={data} />
      </Drawer>
      {children}
    </OrderContext.Provider>
  )
}

export default OrderDrawer

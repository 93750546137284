import { useState, useEffect } from 'react'
import { columns as ticketColums } from '@components/Ticket/Table'
import { columns as deviceColums } from '@components/Device/Table'
import { renderingTabFilter as renderingBackupTab } from '@components/Backup/Table'
import { columns as alertColums } from '@components/Monitoring/Table'
import { Alert, AlertProps, Divider, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	contractColumns,
	overviewColumn
} from '@components/Device/Descriptions'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { useUser } from '@contexts/UserContext'
import GraphTab from '@components/Device/GraphTab'
import { SyncOutlined } from '@ant-design/icons'
import { DeviceContext } from '@contexts/DeviceContext'
import { Col, Row } from 'antd'
import { useGenericContext } from '@hooks/useGenericContext'
import { columns as companyColumn } from '@components/Company/Descriptions'
import { DeviceContextType, IDevice, IDeviceContract } from '@/types/IDevice'
import { api } from '@helpers/api'
import RenderPage from '@components/common/RenderPage'
import CustomDescriptions from '@components/common/CustomDescriptions'
import HardwareContent from '../Hardware/HardwareContent'
import { ISUser } from '@/types/IUser'
import { TabsProps } from 'antd/lib'
import TabPane from 'antd/es/tabs/TabPane'
import { getAdditionalTabs } from '@helpers/getDevicetabs'

const LoadingMessage = ({ message, ...props }: AlertProps) => {
	const { t } = useTranslation()
	return (
		<Alert
			message={t(String(message))}
			showIcon
			style={{ width: '100%' }}
			role=""
			{...props}
			icon={<SyncOutlined spin />}
		/>
	)
}
const useDeviceContext = () =>
	useGenericContext<DeviceContextType>(DeviceContext)

type DeviceTableModels = 'ticket' | 'alert' | 'children'

const CompanyDescriptions = ({ ...props }) => {
	const { data } = useDeviceContext()
	const [loading, setLoading] = useState(true)
	const [company, setCompany] = useState(undefined)
	const { t } = useTranslation()
	useEffect(() => {
		api
			.get(`/company/${data?.company?.uuid}`)
			.then(e => setCompany(e.data))
			.finally(() => setLoading(false))
	}, [data.uuid])
	if (loading) return <LoadingMessage message={t('SEARCHINGCOMPANYINFOS')} />
	return (
		<>
			{company ? (
				<>
					<Divider>{t('COMPANYINFOS')}</Divider>
					<CustomDescriptions
						columns={companyColumn}
						dataSource={company}
						{...props}
					/>
				</>
			) : null}
		</>
	)
}

const useCNDescriptions = () => {
	const [loading, setLoading] = useState(true)

	const { data } = useDeviceContext()
	const [doc, setDoc] = useState<undefined | IDeviceContract>(undefined)
	useEffect(() => {
		api
			.get(`/device/${data.uuid}/contract`)
			.then(e => setDoc(e.data))
			.finally(() => setLoading(false))
	}, [data])
	return { doc, loading }
}

const CNDescriptions = ({
	doc,
	loading
}: {
	doc: IDeviceContract
	loading: boolean
}) => {
	const { t } = useTranslation()
	if (loading) return <LoadingMessage message="SEARCHINGCNINFOS" />
	return (
		<>
			<Divider>{t('CNINFOS')}</Divider>
			{doc ? (
				<CustomDescriptions
					columns={contractColumns}
					dataSource={doc}
					column={1}
				/>
			) : null}
		</>
	)
}

const SupportOverviewItems = () => {
	const { doc, loading } = useCNDescriptions()
	const { data } = useDeviceContext()
	return (
		<>
			<Col xl={{ span: doc ? 12 : 24 }} xs={24}>
				{data?.company?.uuid ? (
					<CompanyDescriptions column={doc ? 1 : { xs: 1, xl: 4 }} />
				) : null}
			</Col>
			{doc ? (
				<Col xl={12} xs={24}>
					<CNDescriptions doc={doc} loading={loading} />
				</Col>
			) : null}
		</>
	)
}
const DeviceTable = ({ model }: { model: DeviceTableModels }) => {
	const { data } = useGenericContext(DeviceContext)
	const types = {
		ticket: {
			url: `ticket?devices=${data.uuid}`,
			columns: ticketColums
		},
		children: {
			url: `device?parent=${data.uuid}`,
			columns: deviceColums
		},
		alert: {
			url: `device/${data.uuid}/alert`,
			columns: alertColums
		}
	}

	return <ResponsiveTableCard {...types[model]} />
}
const OverviewTab = () => {
	const { data } = useGenericContext(DeviceContext)
	const { user } = useUser()
	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<CustomDescriptions columns={overviewColumn} dataSource={data} />
			</Col>
			{user.level > 1 ? <SupportOverviewItems /> : null}
		</Row>
	)
}

const HardwareDevice = () => {
	const { data } = useDeviceContext()
	return <HardwareContent uuid={data.uuid} />
}

const BackupTab = () => {
	const { data } = useDeviceContext()
	const renderBackupTab = renderingBackupTab(`?device=${data.uuid}`)
	return <Tabs items={renderBackupTab} />
}

const items = [
	{
		label: 'OVERVIEW',
		key: '1',
		children: <OverviewTab />
	},
	{
		label: 'TICKETS',
		key: '2',
		children: <DeviceTable model="ticket" />
	},
	{
		label: 'ALERTS',
		key: '3',
		children: <DeviceTable model="alert" />
	},
	{
		label: 'HARDWARE',
		key: '4',
		children: <HardwareDevice />
	},
	{
		label: 'CHILDREN',
		key: '5',
		children: <DeviceTable model="children" />
	},
	{
		label: 'BACKUP',
		key: '6',
		children: <BackupTab />
	},
	{
		label: 'GRAPHS',
		key: '7',
		children: <GraphTab />
	}
]

const returnTabs = (data: IDevice, user: ISUser) => {
	let baseTabs = items.slice(0, 4)

	if (data?.type === 'VM' || user.level === 1) {
		baseTabs = baseTabs.filter(tab => tab.key !== '4')
	}

	if (data?.type === 'VM' || data?.type === 'BAREMETAL') {
		const additionalTabs = getAdditionalTabs(data.type, items, user)
		return [
			...baseTabs.filter(
				tab => !additionalTabs.some(addTab => addTab.key === tab.key)
			),
			...additionalTabs
		]
	}
	return items.slice(0, 3)
}

const DeviceTabs = () => {
	const [tabs, setTabs] = useState<TabsProps['items']>()
	const { t } = useTranslation()
	const { data } = useDeviceContext()
	const { user } = useUser()

	useEffect(() => {
		setTabs(() => {
			return returnTabs(data, user)
		})
	}, [data.uuid])

	return (
		<RenderPage {...{ data: tabs, loading: !tabs }}>
			<Col span={24}>
				{tabs ? (
					<Tabs
						{...{ type: 'card', size: 'large', destroyInactiveTabPane: true }}
						items={tabs.map((tab: any) => ({ ...tab, label: t(tab.label) }))}
					/>
				) : null}
			</Col>
		</RenderPage>
	)
}

export default DeviceTabs

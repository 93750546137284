import { IDns, Zone, ZoneRecord } from '@/types/IDns'
import { Tabs, TabsProps, Tag } from 'antd'
import { generateTabItem } from '@components/common/Tab'
import { ColumnType } from 'antd/es/table'
import useFetch from '@hooks/useFetch'
import { api } from '@helpers/api'
import Loader from '@components/common/Loader'
import ShowDnsActions from '../ActionsDnsTable'
import { useContext } from 'react'
import { ZoneContext } from '@contexts/ZoneContext'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'

export const useZones = () => {
	const { data, loading, error } = useFetch<IDns, undefined>({
		func: () => api.get(`/dns/zone`)
	})
	return { zones: data, loading, error }
}

const RenderStatus = ({ item }: { item: boolean }) => {
	const { t } = useTranslation()
	const color = item ? 'green' : 'red'

	return <Tag color={color}>{item ? t('poweredOn') : t('poweredOff')}</Tag>
}

export const formatDate = (date: string) => {
	return <span>{date ? dayjs(date).format(DATEFORMAT) : ''}</span>
}


const renderName = (zone: ZoneRecord, field: 'created_by' | 'updated_by') => {
	return <span>{zone?.[field]?.name || ''}</span>
}

const columns: ColumnType<ZoneRecord>[] = [
	{
		title: 'EDIT',
		key: 'action',
		dataIndex: 'actions',
		render: (_, zone) => <ShowDnsActions record={zone} />
	},
	{
		title: 'HOSTNAME',
		dataIndex: 'hostname',
		key: 'hostname',
		render: (zone_name: string) => <span>{zone_name}</span>
	},
	{
		title: 'TYPE',
		dataIndex: 'type',
		key: 'type',
		render: (zone_name: string) => <span>{zone_name}</span>
	},
	{
		title: 'VALUE',
		dataIndex: 'data',
		key: 'data',
		render: (zone_name: string) => <span>{zone_name}</span>
	},
	{
		title: 'Online',
		dataIndex: 'online',
		key: 'online',
		render: (zone_name: boolean) => <RenderStatus item={zone_name} />
	},
	{
		title: 'CREATEDAT',
		dataIndex: 'created_at',
		key: 'created_at',
		render: (zone_name: string) => formatDate(zone_name)
	},
	{
		title: 'UPDATEAT',
		dataIndex: 'updated_at',
		key: 'updated_at',
		render: (zone_name: string) => formatDate(zone_name)
	},
	{
		title: 'CREATEDBY',
		dataIndex: 'created_by',
		key: 'created_by',
		render: (_, zone) => renderName(zone, 'created_by')
	},
	{
		title: 'UPDATEBY',
		dataIndex: 'updated_by',
		key: 'updated_by',
		render: (_, zone) => renderName(zone, 'updated_by')
	}
]

interface TabItem {
	key: string
	label: string
	url: string
}

export const useTabsDns = (zones: Zone[]) => {
	const { t } = useTranslation()
	const tabData: TabItem[] = zones.map((item: Zone) => ({
		key: item.zone_name,
		label: t(item.zone_name),
		url: `/dns/zone/${item.zone_name}/record`
	}))

	const renderingDnsTabs: TabsProps['items'] = tabData.map(item =>
		generateTabItem(item, columns)
	)
	return renderingDnsTabs
}

const DnsTabs = () => {
	const { zones, loading } = useZones()
	const renderingTabs: TabsProps['items'] = useTabsDns(zones)

	const { setZoneName } = useContext(ZoneContext)

	const changeZone = (key: string) => {
		setZoneName(key)
	}

	if (loading) return <Loader />

	return (
		<Tabs
			destroyInactiveTabPane={true}
			defaultActiveKey="1"
			items={renderingTabs}
			type="card"
			size="large"
			onChange={changeZone}
		/>
	)
}

export default DnsTabs

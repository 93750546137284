import { Space, Modal } from 'antd'
import Button from '@components/common/Button'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import Icon from '@common/Icon'
import { useNavigate } from 'react-router-dom'
import { ICompany } from '@/types/ICompany'
import ModalCompany from '../Modal'
import { DrawerContext } from '@contexts/DrawerContext'
import useWindowResize from '@hooks/useWindowResize'
import { useGenericContext } from '@hooks/useGenericContext'
import useConfirmAction from '@hooks/useConfirmAction'
import dayjs from 'dayjs'

type CompanyProps = {
  company: ICompany
}

const Actions = ({ company }: CompanyProps) => {
  return (
    <Space>
      <CompanyDrawer company={company} />
      <ModalCompany
        type="primary"
        action="edit"
        icon={<Icon name="fa-light fa-edit" color="white" />}
        data={company}
        data-cy="edit-company"
      />
      <DeleteCompanyButton data={company} />
    </Space>
  )
}

const CompanyDrawer = ({ company }: CompanyProps) => {
  return <InternalCompanyDrawer company={company} />
}

const InternalCompanyDrawer = ({ company }: CompanyProps) => {
  const { width } = useWindowResize()
  const navigate = useNavigate()
  const { openDrawer } = useGenericContext(DrawerContext)
  const onClick = () => {
    if (width > 720) {
      navigate(window.location.pathname)
      openDrawer(company)
      return
    }
    navigate(`/company/${company.uuid}`)
  }
  return (
    <Button
      type="primary"
      onClick={onClick}
      icon={<Icon name="fa-light fa-info" color="white" />}
    />
  )
}

export const DeleteCompanyButton = ({ data }: { data: ICompany }) => {
  const [modal, contextHolder] = Modal.useModal()
  const { t } = useTranslation()
  const { confirm } = useConfirmAction({
    modal,
    func: () => api.delete(`company/${data.uuid}`),
    messages: {
      name: data.name,
      title: 'AREYOUSUREDELETECOMPANY',
      onSucess: 'SUCESSDELETECOMPANY',
      onError: 'ERRORDELETECOMPANY'
    },
    descriptions: infoCompany(data).map(item => {
      return { ...item, label: t(item.label) }
    })
  })
  return (
    <>
      {contextHolder}
      <Button
        style={{ paddingInline: 47 }}
        id="deleteEntity"
        danger
        icon={<Icon name="fa-light fa-trash" color="white" />}
        onClick={confirm}
        block
      >
        {t('DELETECOMPANY')}
      </Button>
    </>
  )
}

const infoCompany = (data: ICompany) => [
  {
    key: '1',
    span: 3,
    label: 'NAME',
    children: <span>{data.name}</span>
  },
  {
    key: '2',
    span: 3,
    label: 'Cpf/Cnpj',
    children: <span>{data.docnum}</span>
  },
  {
    key: '3',
    span: 3,
    label: 'ESTATE',
    children: <span>{data.estate}</span>
  },
  {
    key: '4',
    span: 3,
    label: 'CITY',
    children: <span>{data.city}</span>
  },
  {
    key: '5',
    span: 3,
    label: 'ORIGIN_REASON',
    children: <span>{data.origin_reason}</span>
  },
  {
    key: '6',
    span: 3,
    label: 'MIGRATION_DATE',
    children: (
      <span>
        {data.migration_date &&
          `${dayjs(data.migration_date).format('DD/MM/YYYY')}`}
      </span>
    )
  }
]

export default Actions

import { useUser } from '@contexts/UserContext'
import { Button, Col, Collapse, Form, Input, Row, Typography } from 'antd'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import SplitText from '@components/common/SplitText'
import { useDeviceSecrets } from '@hooks/useDeviceSecrets'

const { Paragraph, Text } = Typography

interface IDeviceSecrets {
	manager?: Array<{
		user: string
		password: string
	}>
	support?: Array<{
		user: string
		password: string
	}>
}

const DeviceSecrets = ({ uuid }: { uuid: string }) => {
	const { user } = useUser()
	return user.level > 1 ? <InternalDeviceSecrets uuid={uuid} /> : null
}

const InternalDeviceSecrets = ({ uuid }: { uuid: string }) => {
	const { hasMaster, checkPassword, data, loading, error } =
		useDeviceSecrets(uuid)
	const { t } = useTranslation()
	return (
		<Form onFinish={checkPassword} disabled={loading}>
			{!hasMaster && !data ? (
				<Row gutter={[8, 8]}>
					<Col xs={{ span: 24 }} xl={{ span: 20 }}>
						<Form.Item name="masterpwd">
							<Input placeholder={t('INSERTTHEMASTERKEY')} />
						</Form.Item>
					</Col>
					<Col xs={{ span: 24 }} xl={{ span: 4 }}>
						<Button
							type="primary"
							htmlType="submit"
							block
							loading={loading}
							data-cy="submit-device-secret"
						>
							{t('CONFIRM')}
						</Button>
					</Col>
					{error?.response?.data?.hasOwnProperty('message') ? (
						<Text type="danger">{error?.response?.data.message as any}</Text>
					) : null}
				</Row>
			) : (
				<SecretData data={data as IDeviceSecrets} />
			)}
		</Form>
	)
}

const CollapseItem = ({ access }: { access: any }) => {
	const { t } = useTranslation()
	return (
		<Row gutter={[8, 8]}>
			{typeof access === 'object'
				? Object.keys(access).map(key => (
					<Col span={24} key={key}>
						{t(key.toUpperCase())}:
						<Paragraph copyable>{access[key]}</Paragraph>
					</Col>
				))
				: access}
		</Row>
	)
}

const getItems = (data: IDeviceSecrets | undefined, t: TFunction) => {
	if (data) {
		const keys = Object.keys(data)
		return keys.map(key => {
			const item = data[key as keyof typeof data]
			return {
				key: key,
				label: key.charAt(0).toUpperCase() + key.slice(1),
				children: Array.isArray(item) ? (
					item.map((access: any) => <CollapseItem access={access} />)
				) : (
					<SplitText>{item ? item : null}</SplitText>
				)
			}
		})
	}
}

const SecretData = ({ data }: { data: IDeviceSecrets }) => {
	const { t } = useTranslation()
	const items = data && getItems(data, t)
	return (
		<>
			{data && typeof data === 'object' ? (
				<Collapse
					items={items}
					collapsible="header"
					defaultActiveKey={Object.keys(data)}
				/>
			) : null}
		</>
	)
}

export default DeviceSecrets

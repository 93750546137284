import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { useEffect, useState } from 'react'
import type { IOption } from '@/types/IOption'
import type { IOrderTerm } from '@/types/IOrder'

const SelectDate = () => {
  const [options, setOptions] = useState<IOption[]>()
  const { t } = useTranslation()

  const fetchMonths = () => {
    api.get('/order/term').then(response => {
      const options: IOption[] = response.data.data.map((item: IOrderTerm) => ({
        value: item.duration_in_months,
        label: `${item.duration_in_months} ${t('MONTHS')}`
      }))
      setOptions(options)
    })
  }

  useEffect(() => {
    fetchMonths()
  }, [])

  return (
    <Form.Item
      name="duration_in_months"
      rules={[{ required: true, message: t('requiredItem') }]}
      data-cy="select-date"
      label={t('EXPIRE_AT')}
    >
      <Select options={options} style={{ width: '100%' }} />
    </Form.Item>
  )
}

export default SelectDate

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postLogin, api } from '@helpers/api'
import { useUser } from '@contexts/UserContext'
import { Image } from './style.js'
import { Link } from 'react-router-dom'
import FormLogin from '../../components/FormLogin'
import { Alert, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import MainBanner from '@components/common/MainBanner/index.js'

const urlPlayStore =
  'https://play.google.com/store/apps/details?id=center.opendata.app&utm_source=webapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
const urlAppleStore = 'https://apps.apple.com/app/open-datacenter/id6449605126'

interface LoginProps {
  errorCredential: boolean
  loading: boolean
  onFinish: any
}

const Error = ({ error }: { error: boolean }) => {
  const { t } = useTranslation()
  return error ? <Alert message={t('errorCredential')} type="error" /> : null
}

const checkStatusFetch = (
  response: any,
  setErrorCredential: any,
  setIsLogged: any,
  setUser: any,
  navigate: any,
  setBalance: any
) => {
  if (response.status === 200) {
    setToken(
      `---OPEN=${response.data.token}---; path=/; max-age=32000; SameSite=Strict`
    )
    handleLogin(setUser, setIsLogged, setBalance)
    redirectRoute(navigate)
  } else {
    setErrorCredential(true)
  }
}

const setToken = (token: string) => {
  document.cookie = token
}

const handleLogin = async (
  setUser: Function,
  setIsLogged: Function,
  setBalance: Function
) => {
  await api.get('/session/user').then(res => {
    setUser(res.data)
  })

  await api.get('/balance').then(res => {
    setBalance(res.data)
    setIsLogged(true)
  })
}

const redirectRoute = (navigate: any) => {
  if (window.location.href.match(/\/login$/g) != null) {
    navigate('/')
  }
}

const LayoutLogin = ({ errorCredential, loading, onFinish }: LoginProps) => {
  return (
    <Row style={{ height: '100vh' }}>
      <MainBanner />
      <Col
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        lg={{ span: 12 }}
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Space direction="vertical">
          <FormLogin loading={loading} onFinish={onFinish} size="large" />
          <Error error={errorCredential} />
          <Space size="small">
            <Link to={urlAppleStore}>
              <Image src="/assets/images/apple-store.png" />
            </Link>
            <Link to={urlPlayStore}>
              <Image src="/assets/images/play-store.png" />
            </Link>
          </Space>
        </Space>
      </Col>
    </Row>
  )
}

const Login = () => {
  const { setIsLogged, setUser, setBalance } = useUser()
  const [errorCredential, setErrorCredential] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onFinish = async (values: { email: string; password: string }) => {
    setLoading(true)
    setErrorCredential(false)
    const { email, password } = values
    const res = await postLogin(email, password).then(e => {
      setLoading(false)
      return e
    })
    checkStatusFetch(
      res,
      setErrorCredential,
      setIsLogged,
      setUser,
      navigate,
      setBalance
    )
  }

  return (
    <LayoutLogin
      errorCredential={errorCredential}
      loading={loading}
      onFinish={onFinish}
    />
  )
}

export default Login

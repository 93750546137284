import type { ColumnsType } from 'antd/es/table'
import Icon from '@common/Icon'
import { Tag, Space, Typography, Row, Button } from 'antd'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import TicketTime from '@components/Ticket/Time'
import { IPriorities, ITicket } from '@/types/ITicket'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import { IStatuses } from '@/types/ITicket'
import ShowActions from '../ActionsTicketTable'
import StatusPriorityTag from '../StatusPriorityTag'
import { useUser } from '@contexts/UserContext'
import { ICompany } from '@/types/ICompany'
import { IDevice } from '@/types/IDevice'
import DrawerProvider, { useDrawerContext } from '@contexts/DrawerContext'
import { DeviceContext } from '@contexts/DeviceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import DeviceDrawer from '@components/Device/DeviceDrawer'
import Company, { CompanyHead } from '@pages/Company'
import { useTranslation } from 'react-i18next'

const { Text, Paragraph } = Typography
export const deviceStyles = {
  display: 'block',
  color: 'white',
  padding: '5px 5px',
  borderRadius: '6px',
  marginBottom: '4px'
}
export interface ITicketTable extends Omit<ITicket, 'status' | 'priority'> {
  status: {
    name: IStatuses
    translated: string
  }
  priority: {
    translated: string
    name: string
  }
}

export const DeviceTag = ({ device }: { device: IDevice }) => {
  return (
    <DeviceDrawer device={device}>
      <InternalDeviceTag />
    </DeviceDrawer>
  )
}

const InternalDeviceTag = () => {
  const { data } = useGenericContext(DeviceContext)
  const { open } = useDrawerContext(`/device/${data.uuid}`, data)

  return (
    <Button type="link" onClick={open}>
      <Typography.Text style={{ ...deviceStyles, backgroundColor: '#1677ff' }}>
        {data.name} <Icon name="fa-light fa-external-link" color="white" />
      </Typography.Text>
    </Button>
  )
}

export const CompanyTag = ({ company }: { company: ICompany }) => (
  <DrawerProvider component={Company} head={CompanyHead}>
    <InternalCompanyTag company={company} />
  </DrawerProvider>
)

const InternalCompanyTag = ({ company }: { company: ICompany }) => {
  const { user } = useUser()
  const { open } = useDrawerContext(`/company/${company.uuid}`, company)

  return (
    <button onClick={user.level > 1 ? open : undefined}>
      <Tag color="purple-inverse" style={{ ...deviceStyles }}>
        <Typography.Text
          style={{ maxWidth: '20ch', color: 'white', paddingRight: '3px' }}
          ellipsis={{ tooltip: company.name }}
        >
          {company.name}
        </Typography.Text>
        {company.opdc_id ? ` #${company.opdc_id} ` : null}
        {user.level > 1 ? (
          <Icon name="fa-light fa-external-link" color="white" />
        ) : null}
      </Tag>
    </button>
  )
}

const colors = ['#0d0d0d', '#0d6efd', '#4a4a49']
interface RenderServiceProps {
  item: string
  index: number
  priority: IPriorities
}

const RenderService = ({ item, index, priority }: RenderServiceProps) => {
  const { t } = useTranslation()
  const color = priority
    ? TICKETSPRIORITIESCOLORS[priority].color
    : colors[index]

  return (
    <>
      {item && !item.hasOwnProperty('time') ? (
        <Tag color={color} key={`tag_${index}_${item}`}>
          {priority ? `${item} - ${t(priority)}` : item}
        </Tag>
      ) : null}
    </>
  )
}

export const columns: ColumnsType<ITicket> = [
  {
    title: 'ACTIONS',
    dataIndex: 'action',
    key: 'action',
    render: (_, data) => <ShowActions data={data} />
  },
  {
    title: '#',
    dataIndex: 'tkid',
    key: 'tkid',
    render: (_, { tkid, is_private }) => (
      <Row style={{ minWidth: '80px' }}>
        <Space>
          {is_private === true ? (
            <Icon name="fa-light fa-lock-keyhole" color="text" size="15px" />
          ) : null}
          <Typography.Text>{tkid}</Typography.Text>
        </Space>
      </Row>
    )
  },
  {
    title: 'DEVICES',
    dataIndex: 'devices',
    key: 'devices',
    render: (_, { devices, company }) => {
      return (
        <Row align="middle" gutter={[8, 8]}>
          <CompanyTag company={company} />
          {devices.map(device => {
            return <DeviceTag device={device.device} key={device.device.uuid} />
          })}
        </Row>
      )
    }
  },
  {
    title: 'TITLE',
    dataIndex: 'title',
    key: 'title',
    render: (_, data) => {
      return (
        <>
          <Paragraph>{data.title}</Paragraph>
          <Space size={[0, 8]} wrap>
            <RenderService item={data.service?.sector?.name} index={0} />
            <RenderService item={data.category?.name} index={1} />
            <RenderService
              item={data.service?.name}
              index={2}
              priority={data.priority}
            />
            <TicketTime data={data} />
          </Space>
        </>
      )
    }
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    render: (_, { status }) => {
      return (
        <>
          {status ? (
            <StatusPriorityTag data={status} obj={TICKETSTATUSESCOLORS} />
          ) : null}
        </>
      )
    }
  },
  {
    title: 'ASSIGNEDTO',
    dataIndex: 'assigned',
    key: 'assigned',
    render: (_, { sector, suser }) => {
      return (
        <>
          <Text
            style={{ display: 'block', marginBottom: '8px', width: '110px' }}
          >
            {sector?.name}
          </Text>
          <Text
            style={{ display: 'block', marginBottom: '8px', width: '110px' }}
          >
            {suser?.name || null}
          </Text>
        </>
      )
    }
  },
  {
    title: 'UPDATEAT',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: text => dayjs(text).format(DATEFORMAT)
  },
  {
    title: 'CREATEDAT',
    dataIndex: 'created_at',
    key: 'created_at',
    render: text => dayjs(text).format(DATEFORMAT)
  }
]

import { useContext, useEffect, useState } from 'react'
import { Row, Form, message, Col } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { FormInstance } from 'antd'
import { RcFile } from 'antd/es/upload'
import { useTranslation } from 'react-i18next'
import TitleForAll from '../TitleForAll'
import DeviceSelectForAll from '../DeviceSelectForAll'
import AutoFillForAll from '../AutofillForAll'
import FilesUploadForAll from '../FilesUploadForAll'
import {
  SupportComponents,
  IsPrivateConfirm
} from '@components/Ticket/TicketItemsForSupport'
import { ITicket, ITicketInCreationOrChanged } from '@/types/ITicket'
import { useUser } from '@contexts/UserContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { ModalContext } from '@contexts/ModalContext'
import { TicketContext } from '@contexts/TicketContext'
import { postAttachmentsforTicket } from '@helpers/postAttachmentsforTicket'
import { api } from '@helpers/api'
import useDisableButtonModal from '@hooks/useDisableButtonModal'
import DatePicker from '@components/common/DatePicker'
import dayjs from 'dayjs'
import { MessageContext } from '@contexts/MessageContext'
import { FollowupContext } from '@contexts/FollowupContext'
import { IFollowup } from '@/types/IFollowup'

interface EditTicketProps {
  id: string
  data?: ITicket
  type: string
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  form: FormInstance
}

const initialValues = (type: string, uuid: string, data?: ITicket) => ({
  title: data?.title,
  status: data?.status,
  assign_to: data?.suser?.uuid ? data?.suser.uuid : uuid,
  priority: data?.priority,
  sector: type !== 'schedule' ? data?.sector?.slug : undefined,
  category: data?.category?.slug,
  company: data?.company?.uuid,
  service: data?.service?.uuid,
  is_private: data?.is_private,
  devices: data?.devices?.map(e => e.device?.uuid)
})

const messageArr = (type: string) => [
  { ticket: 'TICKET' },
  { finished: type === 'schedule' ? 'RESCHEDULED' : 'EDITED' },
  { executing: type === 'schedule' ? 'RESCHEDULINGTICKET' : 'EDITINGTICKET' },
  { unexpected: 'UNEXPECTEDERROR' },
  { uploading: 'UPLOADINGFILES' },
  { successFiles: 'SUCESSUPLOADFILES' },
  { failUploadFiles: 'FAILUPLOADFILES' }
]

const translatedMessages = (
  t: (message: string) => string,
  messageArr: Array<{ [key: string]: string }>
) => {
  const obj: { [key: string]: string } = {}

  messageArr.map((message: { [key: string]: string }) => {
    const key = Object.keys(message)[0]
    obj[key] = t(Object.values(message)[0])
  })
  return obj
}

const separateTicketAndFollowup = (newTicket: ITicketInCreationOrChanged) => {
  const { followup, f_is_private, attachments, ...ticketObj } = newTicket
  const followupObj = { followup, is_private: f_is_private }
  return { followupObj, ticketObj, attachments }
}

const changeObjIfSchedule = (obj: ITicketInCreationOrChanged) => {
  if (obj?.schedule_at && dayjs() < dayjs(obj?.schedule_at))
    return { type: 'PRETICKET', ...obj }
  return obj
}

const postFollowup = async (tkid: string | number, followupObj: any) => {
  return await api.post(`/ticket/${tkid}/followup`, followupObj)
}

const postAttachments = async (
  attachments: RcFile[],
  tkid: string | number,
  message: { api: MessageInstance; messages: { [key: string]: string } },
  is_private: boolean
) => {
  if (attachments && attachments.length > 0) {
    message.api.loading(message.messages.uploading, 0)
    return await postAttachmentsforTicket(attachments, tkid, is_private)
      .then(() => message.api.success(message.messages.successFiles, 2))
      .catch(() => message.api.error(message.messages.failUploadFiles, 2))
      .finally(() => {
        message.api.destroy()
      })
  }
}
interface EditFunctionType {
  newTicket: ITicketInCreationOrChanged
  tkid: string | number
  message: { api: MessageInstance; messages: { [key: string]: string } }
  close: (data: ITicket) => void
  addFollowup: (followup: IFollowup) => void
}
const editTicket = async ({
  newTicket,
  tkid,
  message,
  close,
  addFollowup
}: EditFunctionType) => {
  const { followupObj, ticketObj, attachments } =
    separateTicketAndFollowup(newTicket)
  message.api.loading(message.messages.executing)
  return await api
    .put(`/ticket/${tkid}`, changeObjIfSchedule(ticketObj))
    .then(async ticket => {
      await postFollowup(tkid, followupObj).then(followup => {
        if (addFollowup) {
          addFollowup(followup.data)
        }
        close(ticket.data)
        message.api.success(
          `${message.messages.ticket} ${tkid} ${message.messages.finished}`,
          2
        )
        postAttachments(attachments, tkid, message, followupObj.is_private)
      })
    })
    .catch(() => message.api.error(`${message.messages.unexpected}`, 2))
}

const useRequestTicket = (close: () => void, messages: any) => {
  const { data } = useGenericContext(TicketContext)
  const messageApi = useGenericContext(MessageContext)
  const context = useContext(FollowupContext)
  const [disabled, setDisabled] = useState(false)
  const add = (followup: IFollowup) => {
    if (context) {
      context.addFollowup(followup)
      context.refresh()
    }
  }

  const onFinish = async (alteredTicket: ITicketInCreationOrChanged) => {
    setDisabled(true)
    await editTicket({
      newTicket: alteredTicket,
      tkid: data?.tkid,
      message: { api: messageApi, messages },
      close,
      addFollowup: add
    }).finally(() => setDisabled(false))
  }
  return { disabled, onFinish }
}

const useEditTicket = (form: FormInstance, type: string) => {
  const { t } = useTranslation()
  const context = useGenericContext(ModalContext)
  const { data, setData } = useGenericContext(TicketContext)
  const { user } = useUser()

  useEffect(() => {
    form.setFieldsValue(initialValues(type, user.uuid, data))
  }, [data])

  const close = (data: ITicket) => {
    setData(data)
    form.resetFields()
    context.onCancel?.()
  }

  const messages = translatedMessages(t, messageArr(type))
  const { onFinish, disabled } = useRequestTicket(close, messages)
  return { onFinish, data, disabled }
}

const RenderSupportComponents = ({
  type,
  form
}: Pick<EditTicketProps, 'type' | 'form'>) => {
  const { user } = useUser()
  const { data } = useGenericContext(TicketContext)
  return (
    <>
      <TitleForAll initialValue={data?.title} />
      {type === 'schedule' ? (
        <Col xs={{ span: 24 }} xl={{ span: 6 }}>
          <DatePicker name="schedule_at" />
        </Col>
      ) : null}
      <SupportComponents data={data} type={type} form={form} />
      <DeviceSelectForAll data={data} span={12} />
      {user?.level && user.level > 1 ? (
        <IsPrivateConfirm defaultValue={data?.is_private} />
      ) : null}
    </>
  )
}

const EditTicket = ({ type, id, setDisabled }: EditTicketProps) => {
  const { user } = useUser()
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const { onFinish, disabled } = useEditTicket(form, type, messageApi)
  useDisableButtonModal(setDisabled, disabled)
  return (
    <Form id={id} form={form} layout="vertical" onFinish={onFinish}>
      {contextHolder}
      <Row gutter={[16, 8]}>
        {user.level > 1 ? (
          <RenderSupportComponents type={type} form={form} />
        ) : null}
        <FilesUploadForAll span={user.level > 1 ? 12 : 24} />
        <AutoFillForAll client={user?.level === 1} isFollowup />
        {user?.level && user.level > 1 && <IsPrivateConfirm isFollowup />}
      </Row>
    </Form>
  )
}

export default EditTicket

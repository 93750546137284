import { useState } from 'react'
import Title from '@components/common/Title'
import { Modal, Row, Col, Typography, Tag, Button, Space } from 'antd'
import IconButton from '@components/common/IconButton'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ModalFrame from '../ModalFrame'
import { IDevice } from '@/types/IDevice'
import { DeviceContext } from '@contexts/DeviceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { api } from '@helpers/api'
import { useUser } from '@contexts/UserContext'
import { useNavigate } from 'react-router-dom'

interface PowerDeviceButtonProps {
	data: IDevice
	disabled: boolean
	action: (value: string) => void
}
const useDeviceAction = () => {
	const { t } = useTranslation()
	const { data, setData } = useGenericContext(DeviceContext)

	const [modal, contextHolder] = Modal.useModal()
	const [disabled, setDisabled] = useState(false)

	const action = (value: string) => {
		modal.confirm({
			title: `${t(value.toUpperCase())}  ${t('Device')}`,
			async onOk() {
				setDisabled(true)
				await api
					.post(`device/${data.uuid}/${value}`)
					.then(e => setData(e.data))
					.finally(() => setDisabled(false))
			},
			onCancel() { }
		})
	}
	return { data, disabled, contextHolder, modal, action }
}
const ConsoleButton = ({ data }: { data: IDevice }) => {
	const { t } = useTranslation()
	return (
		<>
			{data.state === 'poweredOn' ? (
				<ModalFrame
					icon="fa-light fa-tv"
					link={`/device/${data.uuid}/console?wss=true`}
					method="post"
				>
					{t('CONSOLE')}
				</ModalFrame>
			) : null}
		</>
	)
}
const PowerDeviceButton = ({
	data,
	disabled,
	action
}: PowerDeviceButtonProps) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const handleClick = () => {
		const actionType = data.state === 'poweredOn' ? 'poweroff' : 'poweron'
		action(actionType)
	}

	return (
		<IconButton
			name="fa-light fa-power-off"
			data-cy={`device-${data.state}`}
			style={{
				backgroundColor: data.state === 'poweredOn' ? theme.red : theme.green
			}}
			disabled={disabled || data.state === 'suspended'}
			onClick={handleClick}
		>
			{data.state === 'poweredOn' ? t('POWEROFF') : t('POWERON')}
		</IconButton>
	)
}

const VMDeviceButtons = () => {
	const { t } = useTranslation()
	const { data, disabled, contextHolder, action } = useDeviceAction()
	return (
		<>
			{contextHolder}
			<PowerDeviceButton {...{ data, disabled, action }} />
			<Button
				data-cy="device-reset"
				type="primary"
				onClick={() => action('reset')}
				disabled={disabled || data.state !== 'poweredOn'}
				icon={<span className="fa-light fa-arrows-retweet"></span>}
			>
				{t('RESET')}
			</Button>
			<ConsoleButton {...{ data }} />
		</>
	)
}


const EditDeviceButton = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { data } = useGenericContext(DeviceContext)
	return (
		<IconButton
			name='fa-light fa-pen'
			onClick={() => navigate(`/device/${data?.uuid}/edit`)}
		>
			{t('EDITDEVICE')}
		</IconButton>
	)
}

const NetboxButton = () => {
	const { data } = useGenericContext(DeviceContext)
	const { user } = useUser()
	if (user?.level > 1 && data?.netbox_id)
		return (
			<a
				href={`https://netbox.opendata.center/dcim/devices/${data.netbox_id}`}
				target="_blank"
				data-cy="NETBOXLINK" rel="noreferrer"
			>
				<IconButton name="fa-light fa-link">Netbox</IconButton>
			</a>
		)
}

const InternalDeviceActionButtons = () => {
	const { t } = useTranslation()
	const { data } = useGenericContext(DeviceContext)

	if (data?.type === 'VM') {
		return <VMDeviceButtons />
	}

	if (data?.type === 'BAREMETAL')
		return (
			<a href={`http://${data.domain}`} target="_blank" data-cy="HOSTLINK" rel="noreferrer">
				<IconButton name="fa-light fa-link">{t('ACESSHOST')}</IconButton>
			</a>
		)

	return null
}

const DeviceActionButtons = () => {
	const { user } = useUser()
	return (
		<Col xs={24} xl={{ span: 10, offset: 6 }}>
			<Row justify="end" gutter={10} style={{ gap: '10px' }}>
				{user.level !== 1 && <>
					<EditDeviceButton />
					<NetboxButton />
				</>}
				<InternalDeviceActionButtons />
			</Row>
		</Col>
	)
}

const DeviceHead = () => {
	const { data } = useGenericContext(DeviceContext)
	const { user } = useUser()

	return (
		<Row style={{ width: '100%' }} align="middle">
			<Col span={24}>
				<Row align="middle">
					<Col xs={24} xl={8}>
						<Title name={data.name}>
							<Typography.Title style={{ margin: 0 }}>
								<Space>
									{data.name}{' '}
									{data.alias ? (
										<Tag color="geekblue-inverse">{data.alias}</Tag>
									) : null}
								</Space>
							</Typography.Title>
						</Title>
					</Col>
					{Array.isArray(user?.roles) && user.roles?.includes('RO') ? null : (
						<DeviceActionButtons />
					)}
				</Row>
			</Col>
		</Row>
	)
}

export default DeviceHead

import useTimeRange from '@hooks/useTimeRange'
import { Row, Col, DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

const TimeRangePicker = () => {
  const { t } = useTranslation()
  const disableFutureDates = (date: Dayjs) => date > dayjs()

  const renderRangePicker = (
    paramStart: string,
    paramEnd: string,
    placeholders: string[]
  ) => {
    const range = useTimeRange(paramStart, paramEnd, true)

    return (
      <Col>
        <RangePicker
          format="DD-MM-YYYY HH:mm"
          allowClear
          showTime
          disabledDate={disableFutureDates}
          style={{ width: '100%' }}
          placeholder={placeholders}
          data-cy="filterTime"
          {...range}
        />
      </Col>
    )
  }

  return (
    <Row gutter={[10, 15]}>
      {renderRangePicker('created_at_start', 'created_at_end', [
        t('CREATED_START'),
        t('CREATED_END')
      ])}
      {renderRangePicker('updated_at_start', 'updated_at_end', [
        t('UPDATED_START'),
        t('UPDATED_END')
      ])}
    </Row>
  )
}

export default TimeRangePicker

import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import useSearchParams from '@hooks/useSearchParams'

const { Option } = Select

const useSortSelect = () => {
  const params = useSearchParams(true)
  const { t } = useTranslation()

  const sortOptions = [
    { value: 'priority', label: t('PRIORITY') },
    { value: 'status', label: t('STATUS') },
    { value: 'created_at', label: t('CreatedAt') },
    { value: 'updated_at', label: t('UpdatedAt') }
  ]

  const getSelectedSort = () => {
    return params.getItem('sort') || ''
  }

  const onChangeSort = (value: string) => {
    if (value) {
      params.setItem('sort', value)
    }
  }

  const selectedSort = getSelectedSort() || undefined

  return { selectedSort, onChangeSort, sortOptions }
}

const SortSelect = () => {
  const { t } = useTranslation()
  const { selectedSort, onChangeSort, sortOptions } = useSortSelect()

  return (
    <Select
      style={{ width: '100%' }}
      placeholder={t('SORT_BY')}
      value={selectedSort || undefined}
      onChange={onChangeSort}
      allowClear
      data-cy="filterSort"
    >
      {sortOptions.map(option => (
        <Option key={option.value} value={option.value}>
          {t(option.label)} (Asc)
        </Option>
      ))}
      {sortOptions.map(option => (
        <Option key={`-${option.value}`} value={`-${option.value}`}>
          {t(option.label)} (Desc)
        </Option>
      ))}
    </Select>
  )
}

export default SortSelect

import { TabsProps, Tabs, Row, Col } from 'antd'
import { useParams } from 'react-router-dom'
import Title from '@common/Title'
import Descriptions from '@components/common/Descriptions'
import { columns as companyColumns } from '@components/Company/Descriptions'
import { columns as deviceColums } from '@components/Device/Table'
import { columns as alertColums } from '@components/Monitoring/Table'
// import { columns } from '@components/Ticket/Table'
import { renderingTabFilter as backupTab } from '@components/Backup/Table'
import ResposiveTableCard from '@common/ResponsiveTableCard'
import { ICompany } from '@/types/ICompany'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { api } from '@helpers/api'
import translateTabs from '@helpers/translateTabs'
import RenderPage from '@common/RenderPage'
import { TableCardProps } from '@/types/ITableCard'
import useFetch from '@hooks/useFetch'
import { CompanyContext } from '@contexts/CompanyContext'
import Loader from '@components/common/Loader'
import ModalCompany from '@components/Company/Modal'
import Icon from '@components/common/Icon'
import { DeleteCompanyButton } from '@components/Company/Actions'

const BackupTab = () => {
  const company = useGenericContext(CompanyContext)
  return <Tabs items={backupTab(`?company=${company.uuid}`)} />
}

const CompanyTable = <T,>({ url, ...props }: TableCardProps<T>) => {
  const company = useGenericContext(CompanyContext)
  const cancelSearch = true
  return (
    <ResposiveTableCard
      url={`${url}?company=${company.uuid}`}
      {...props}
      cancelSearch={cancelSearch}
    />
  )
}

const CompanyOverview = () => {
  const company = useGenericContext(CompanyContext)
  return (
    <Descriptions
      columns={companyColumns}
      bordered
      {...{
        dataSource: company,
        size: 'small',
        column: { xs: 1, xl: 4 }
      }}
    />
  )
}

export const fetchParams = {
  initialValue: undefined,
  params: undefined
}

export const useCompany = (attr: string | undefined) => {
  const { uuid } = useParams()
  const companyUuid = attr ? attr : uuid
  const { t } = useTranslation()
  const tabs = translateTabs(items, t)
  const { data, loading, error } = useFetch<ICompany, undefined>({
    func: () => api.get(`company/${companyUuid}`),
    ...fetchParams
  })
  return { company: data, loading, error, tabs }
}

export const CompanyHead = ({ data }: { data: ICompany }) => {
  const { t } = useTranslation()
  return (
    <Row
      style={{ width: '100%', paddingBottom: 10 }}
      justify="space-between"
      align="middle"
    >
      <Col xs={24} xl={12}>
        <Title name={data.name} style={{ margin: 0 }} />
      </Col>
      <Col
        xs={24}
        xl={{
          span: 8,
          offset: 4
        }}
      >
        <Row align="middle" justify={'end'} gutter={[8, 8]}>
          <Col xs={24} xl={12}>
            <ModalCompany
              type="primary"
              action="edit"
              name="Edit"
              icon={<Icon name="fa-light fa-edit" color="white" />}
              data={data}
              data-cy="edit-company"
              style={{ paddingInline: 47 }}
              block
            >
              {t('EDITCOMPANY')}
            </ModalCompany>
          </Col>
          <Col xs={24} xl={12}>
            <DeleteCompanyButton data={data} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const Company = ({ data }: { data?: ICompany | undefined }) => {
  const { company, loading, error, tabs } = useCompany(data?.uuid)
  if (loading) return <Loader />
  return (
    <RenderPage {...{ data: company, loading, error }}>
      <CompanyContext.Provider value={company}>
        {!data?.uuid ? <CompanyHead data={company} /> : null}
        <Tabs {...{ items: tabs, type: 'card' }} destroyInactiveTabPane />
      </CompanyContext.Provider>
    </RenderPage>
  )
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'OVERVIEW',
    children: <CompanyOverview />
  },
  //   {
  //     key: '2',
  //     label: 'TICKETS',
  //     children: <CompanyTable columns={columns} url="/ticket" />
  //   },
  {
    key: '3',
    label: 'DEVICES',
    children: <CompanyTable columns={deviceColums} url="/device" />
  },
  {
    key: '4',
    label: 'ALERTS',
    children: <CompanyTable columns={alertColums} url="/devicealert" />
  },
  {
    key: '5',
    label: 'BACKUP',
    children: <BackupTab />
  }
]

export default Company

import { useState } from 'react'
import { Modal, Col, UploadFile, Form, Upload } from 'antd'
import { RcFile, DraggerProps } from 'antd/es/upload'
import { InboxOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { getBase64 } from '@helpers/getBase64'

const { Dragger } = Upload

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const TicketFormDragger = ({ ...props }: DraggerProps) => {
  const { t } = useTranslation()
  return (
    <Dragger
      {...props}
      customRequest={({ onSuccess }) => onSuccess?.('')}
      multiple
      listType="picture-card"
      style={{
        //maxWidth: '200px',
        maxHeight: '80px',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <p className="ant-upload-drag-icon" style={{ height: '25px' }}>
        {' '}
        <InboxOutlined />{' '}
      </p>
      <p className="ant-upload-text" style={{ fontSize: '11px' }}>
        {t('DRAGFILE')}
      </p>
      <p className="ant-upload-hint" style={{ fontSize: '10px' }}>
        {' '}
        {t('100MBMAXIMUMSIZE')}{' '}
      </p>
    </Dragger>
  )
}

const FilesUploadForAll = ({ span }: { span: number }) => {
  const { t } = useTranslation()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }
    setPreviewImage(file.url ?? (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    )
  }

  return (
    <Col xs={24} xl={span} style={{ height: 'max-content' }}>
      <Form.Item
        label={t('OptionalAttachment')}
        name="attachments"
        getValueFromEvent={normFile}
        valuePropName="fileList"
      >
        <TicketFormDragger
          onPreview={handlePreview}
          onChange={(e: { fileList: UploadFile[] }) => setFileList(e.fileList)}
        />
      </Form.Item>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Col>
  )
}

export default FilesUploadForAll

import {
  Button,
  ButtonProps,
  Col,
  Drawer,
  Form,
  Row,
  Select,
  Space,
  Tag
} from 'antd'
import useModal from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import useSearchSelect from '@hooks/useSearchSelect'
import { api } from '@helpers/api'
import { IProduct } from '@/types/IOrder'
import { IProductItem } from '@/types/IProduct'
import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { Text } from '@components/common/Text'
import ProductForm from '../Form'
import HiddenFormItem from '@components/common/HiddenFormItem'
import { ModalContext } from '@contexts/ModalContext'
import ProductChildrenDrawer from '../ProductChildrenDrawer'

const Option = Select.Option

interface ProductValuesSelectProps {
  value: IProduct | undefined
  setValue: Dispatch<SetStateAction<IProduct | undefined>>
}

interface ProductModal extends ButtonProps {
  data?: IProduct | undefined
}

const formatInitialValues = (data: IProduct | undefined) => {
  if (data)
    return {
      ...data,
      dc: data?.dc?.uuid,
      items: data.items.map((item: IProductItem) => ({
        ...item,
        price: item?.price?.toFixed(2),
        step: item?.step ? Math.round(item?.step) : null,
        children: item?.children?.map(e => ({
          ...e,
          price: e.price.toFixed(2)
        }))
      }))
    }
}

const SubmitButton = (props: ButtonProps) => {
  return (
    <Form.Item noStyle>
      <Button htmlType="submit" {...props} />
    </Form.Item>
  )
}
const useProductModal = () => {
  const { open, onClose, onOpen } = useModal()
  const drawerChildrenProps = { open, onClose }
  const onFinish = () => {
    onOpen()
  }
  return { onFinish, drawerChildrenProps }
}

const ProductValuesSelect = ({ value, setValue }: ProductValuesSelectProps) => {
  const { t } = useTranslation()
  const form = useFormInstance()
  const { options, ...props } = useSearchSelect<IProduct[], any>({
    func: async (search = '') =>
      await api
        .get(`/product?search=${search}`)
        .then(e => e.data.data as IProduct[])
  })

  const onSelect = (item: IProduct['uuid']) => {
    let selectedOption = options?.filter(option => option.uuid === item)[0]
    if (selectedOption) {
      selectedOption = {
        ...selectedOption,
        dc: selectedOption.dc.uuid
      }
      form.setFieldsValue(selectedOption)
      setValue(selectedOption)
    }
  }

  return (
    <Row style={{ marginLeft: '8px' }} align="middle">
      <Text>{value ? t('CLONEDBY') + ':' : null}</Text>
      <Select
        data-cy="clone-product-select"
        value={value?.uuid}
        {...props}
        placeholder={t('CLONEBYEXISTINGPRODUCT')}
        onSelect={onSelect}
      >
        {options?.map(product => (
          <Option
            key={product.uuid}
            label={product.name}
            data-cy="clone-option-select"
          >
            <Space>
              {product.name}
              <Tag color="blue-inverse">{product.dc.shortname}</Tag>
            </Space>
          </Option>
        ))}
      </Select>
    </Row>
  )
}

const DrawerHeader = ({ data }: Pick<ProductModal, 'data'>) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<IProduct | undefined>(undefined)
  const form = Form.useFormInstance()
  return (
    <Space>
      <Col>{data ? <HiddenFormItem name="uuid" /> : null}</Col>
      <SubmitButton
        onClick={() => form.submit()}
        type="primary"
        data-cy="confirm-values-button"
      >
        {t('CONFIRMVALUES')}
      </SubmitButton>
      <Col span={24}>
        <ProductValuesSelect {...{ ...{ value, setValue } }} />
      </Col>
    </Space>
  )
}

const ProductModal = ({ data, ...props }: ProductModal) => {
  const { onFinish, drawerChildrenProps } = useProductModal()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { open, onClose, onOpen } = useModal()
  useEffect(() => {
    if (open && data) {
      form.setFieldsValue(formatInitialValues(data))
    }
  }, [open, data, form])

  return (
    <ModalContext.Provider value={{ open, onClose, onOpen }}>
      <Form
        form={form}
        initialValues={formatInitialValues(data)}
        onFinish={onFinish}
        layout="vertical"
      >
        <Drawer
          width={'100%'}
          open={open}
          onClose={onClose}
          extra={<DrawerHeader data={data} />}
          title={
            !data ? t('CREATEPRODUCT') : t('UPDATEPRODUCT') + ' ' + data?.name
          }
        >
          <ProductForm />
          <ProductChildrenDrawer {...drawerChildrenProps} data={data} />
        </Drawer>
        <Button {...props} onClick={onOpen} />
      </Form>
    </ModalContext.Provider>
  )
}

export default ProductModal

import { SearchSelectWithForm } from '@components/common/SearchSelectWithForm'
import useSearchSelect from '@hooks/useSearchSelect'
import {
  Form,
  Col,
  Switch,
  Space,
  InputNumber,
  FormItemProps,
  Button
} from 'antd'
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { NamePath } from 'antd/es/form/interface'
import { DefaultOptionType } from 'antd/es/select'
import { useTranslation } from 'react-i18next'
import InputWithForm from '@components/common/InputWithForm'
import { api } from '@helpers/api'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { AddItemButtonProps, ProductFormListProps } from '@/types/IProduct'
import { Select } from 'antd/lib'
import { useEffect, useState } from 'react'
import { PRODUCTTYPES } from '@constants/producttypes'
import HiddenFormItem from '@components/common/HiddenFormItem'
import { mapCleanFormatFilter } from '@helpers/MapCleanFormatFilter'

export const ProductRequiredCheckbox = ({ name }: { name: number }) => {
  const { t } = useTranslation()
  return (
    <Col span={3}>
      <Form.Item
        initialValue={true}
        rules={[{ required: true }]}
        name={[name, 'required']}
        label={t('REQUIRED')}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </Col>
  )
}

export const ProductPriceInput = ({ name }: { name: NamePath }) => {
  const { t } = useTranslation()
  return (
    <Col span={3}>
      <Form.Item name={name} label={t('PRICE')} rules={[{ required: true }]}>
        <InputNumber min="0" step="0.01" />
      </Form.Item>
    </Col>
  )
}

export const ProductPreandPostFixInputs = ({ name }: { name: number }) => {
  const { t } = useTranslation()
  return (
    <>
      {['prefix', 'postfix'].map(item => (
        <Col span={4} key={item}>
          <InputWithForm
            required={item === 'postfix'}
            name={[name, item]}
            label={t(item.toUpperCase())}
          />
        </Col>
      ))}
    </>
  )
}

export const ProductOverrideInput = ({ name }: { name: number }) => {
  const { t } = useTranslation()
  return (
    <Col span={6}>
      <InputWithForm
        required={true}
        name={[name, 'override_name']}
        label={t('OVERRIDENAME')}
      />
    </Col>
  )
}

export const AddItemButton = ({
  add,
  span,
  path,
  ...props
}: AddItemButtonProps) => {
  const { t } = useTranslation()
  return (
    <Col
      span={span || 24}
      style={{
        margin: '16px 0 4px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const item = path ? getFieldValue(path) : null
          return (
            <Button
              data-cy="add-item-button"
              onClick={() => add()}
              block
              type="primary"
              {...props}
              disabled={item?.type === 'ITEM'}
            >
              {t('NEWITEM')}
            </Button>
          )
        }}
      </Form.Item>
    </Col>
  )
}

const getDCS = async (search = ''): Promise<DefaultOptionType[]> => {
  return await api
    .get(`/dc?search=${search}`)
    .then(e => mapCleanFormatFilter(e.data, 'uuid', 'name'))
}

export const ProductItemInput = (props: FormItemProps) => {
  const { t } = useTranslation()
  return (
    <Col span={4}>
      <InputWithForm
        {...props}
        rules={[{ required: true }]}
        label={t('ITEM')}
      />
    </Col>
  )
}

export const ProductTypeSelect = ({
  name,
  path
}: {
  name: any
  path: NamePath
}) => {
  const { t } = useTranslation()
  const rule = [
    'REMOVEALLSUBITEMSTOSELECTTHISOPTION',
    'THEOPTIONITEMDONTALLOWSUBITEMS'
  ]
  const childrenObserver = Form.useWatch(path)
  const defaultOptions = PRODUCTTYPES.map(e => ({
    label: t(e),
    value: e,
    key: e,
    children: e
  }))
  const form = Form.useFormInstance()

  const [options, setOptions] = useState<Array<any>>(defaultOptions)
  useEffect(() => {
    const item = form.getFieldValue(path)
    if (item?.children?.length > 0)
      return setOptions(prev =>
        prev.map(e => {
          return e.value === 'ITEM'
            ? { ...e, disabled: true, label: t('ITEM') + ' ' + t(rule[0]) }
            : e
        })
      )
    return setOptions(defaultOptions)
  }, [childrenObserver])

  return (
    <Col span={4}>
      <Form.Item
        shouldUpdate
        name={[name, 'type']}
        rules={[{ required: true }]}
        label={t('TYPE')}
        tooltip={t(rule[1])}
      >
        <Select
          options={options}
          style={{ width: '100%' }}
          data-cy={[...path, 'type'].join('_')}
        />
      </Form.Item>
    </Col>
  )
}

export const FormItemActions = ({
  actions,
  index,
  field,
  path
}: ProductFormListProps) => {
  const form = useFormInstance()
  const values = form.getFieldsValue()
  const item = form.getFieldValue(path)
  const onClose = () => {
    if (item?.uuid) api.delete(`/product/${values.uuid}/item/${item.uuid}`)
    actions.remove(field.name)
  }
  return (
    <Space>
      <HiddenFormItem name="uuid" />
      <CaretUpOutlined onClick={() => actions.move(index, index - 1)} />
      <CaretDownOutlined onClick={() => actions.move(index, index + 1)} />
      <CloseOutlined onClick={onClose} data-cy={`delete-item-${item.uuid}`} />
    </Space>
  )
}

export const DCSelect = () => {
  const props = useSearchSelect<DefaultOptionType[], any>({
    func: getDCS
  })
  return <SearchSelectWithForm {...props} name="dc" label="DC" span={8} />
}

import type { FollowupWithHistory } from '@/types/IFollowup'
import { api } from '@helpers/api'
import type { IRequestList } from '@/types/ICollection'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState
} from 'react'
import { ITicket } from '@/types/ITicket'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import { useTranslation } from 'react-i18next'

interface FollowupProviderProps extends PropsWithChildren {
  tkid: ITicket['tkid']
}

interface FollowupContextType {
  followup: IRequestList<FollowupWithHistory> | undefined
  setFollowup: Dispatch<
    SetStateAction<IRequestList<FollowupWithHistory> | undefined>
  >
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  isError: boolean
  refresh: () => void
  tkid: ITicket['tkid']
  addFollowup: (newFollowup: FollowupWithHistory) => void
}

export const FollowupContext = createContext<FollowupContextType | undefined>(
  undefined
)

const checkFollowup = (
  list: IRequestList<FollowupWithHistory> | undefined,
  newFollowup: FollowupWithHistory
) => {
  if (
    list?.data?.length &&
    !list?.data.filter(e => e.uuid === newFollowup.uuid).length
  )
    return { ...list, data: [...list?.data, newFollowup] }
  return list
}

const useRequestFollowup = (
  tkid: ITicket['tkid'],
  setFollowup: Dispatch<
    SetStateAction<IRequestList<FollowupWithHistory> | undefined>
  >,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setIsError: Dispatch<SetStateAction<boolean>>
) => {
  const { t } = useTranslation()
  const messageApi = useGenericContext(MessageContext)
  const refresh = async () => {
    setLoading(true)
    try {
      const followup = await api.get(`/ticket/${tkid}/followup`)
      const ticketHistory = await api.get(`/ticket/${tkid}/history`)
      const sortData: FollowupWithHistory[] = [
        ...followup?.data?.data,
        ...ticketHistory?.data?.data
      ]
      sortData.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
      setFollowup({ data: sortData })
    } catch {
      setIsError(true)
      messageApi.error(t('FOLLOWUPREQUESTERROR'))
    } finally {
      setLoading(false)
    }
  }
  return refresh
}

const useFollowupProvider = (tkid: ITicket['tkid']) => {
  const [isError, setIsError] = useState(false)
  const [followup, setFollowup] = useState<
    IRequestList<FollowupWithHistory> | undefined
  >()
  const [loading, setLoading] = useState(true)

  const refresh = useRequestFollowup(tkid, setFollowup, setLoading, setIsError)

  const addFollowup = (newFollowup: FollowupWithHistory) => {
    setFollowup((prev: IRequestList<FollowupWithHistory> | undefined) =>
      checkFollowup(prev, newFollowup)
    )
  }

  return {
    tkid,
    followup,
    setFollowup,
    refresh,
    loading,
    setLoading,
    addFollowup,
    isError
  }
}

const FollowupProvider = ({ children, tkid }: FollowupProviderProps) => {
  const values = useFollowupProvider(tkid)
  return (
    <FollowupContext.Provider value={values}>
      {children}
    </FollowupContext.Provider>
  )
}

export default FollowupProvider

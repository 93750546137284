import { Col, Space, Typography } from "antd"
import { Logo } from '../../../pages/Login/style.js'
import useWindowResize from "@hooks/useWindowResize"

const MainBanner = () => {
  const { width } = useWindowResize()
  return (
    <Col
      sm={{ span: 24 }}
      xs={{ span: 24 }}
      lg={{ span: 12 }}
      style={{
        height: `${width > 720 ? '100vh' : '40vh'}`,
        backgroundColor: '#005892',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Space
        direction="vertical"
        style={{ textAlign: 'center' }}
      >
        <Logo src="/assets/images/logo-opendatacenter-white.png" />
        <Typography.Text style={{ fontSize: '32px', color: 'white' }}>
          Cloud Manager
        </Typography.Text>
      </Space>
    </Col>
  )
}

export default MainBanner

import { CloseOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
  Form,
  Row,
  Col,
  Button,
  Card,
  message,
  Popconfirm,
  DatePicker,
  Checkbox,
  FormInstance
} from 'antd'
import { SelectInput } from '@components/common/SelectInput'
import PhonesForm from '@components/common/PhonesForm'
import { api } from '@helpers/api'
import TextInput from '@components/common/TextInput'
import { useTranslation } from 'react-i18next'
import { rulesUser } from '@helpers/rulesUser'
import { namesinputfields } from '@constants/userinputfields'
import type { ICompany } from '@/types/ICompany'
import type { IUser } from '@/types/IUser'
import { useEffect } from 'react'

interface FormUserProps {
  data: ICompany['users'] | undefined
  action: string
  nameForm: keyof typeof namesinputfields
  title: string
  form: FormInstance
}

const removeFormItem = (
  tags: Array<string>,
  length: number,
  remove: (index: number | number[]) => void,
  name: number
) => {
  if (!(tags?.length && length < 2)) remove(name)
}

const DeleteUserButton = ({ user, remove }: { user: IUser; remove: any }) => {
  const { t } = useTranslation()
  const deleteUser = async () => {
    if (user?.uuid) {
      await api
        .delete(`user/${user.uuid}`)
        .then(() =>
          message.success(`${t('SUCESSDELETEUSER')}: ${user.name}`, 3)
        )
        .catch(() => message.error(t('ERRORDELETEUSER'), 5))
    }
  }

  return (
    <Popconfirm
      description={`${t('AREYOUSUREDELETEUSER')} ${user.name}?`}
      title={t('DELETEUSER')}
      onConfirm={async () => {
        await deleteUser()
        remove()
      }}
    >
      <CloseOutlined data-cy="delete-exist-user" />
    </Popconfirm>
  )
}

const FormUser = ({ data, action, nameForm, title, form }: FormUserProps) => {
  const { t } = useTranslation()
  const fieldRules: any = rulesUser(t)
  const inputValues = namesinputfields[nameForm]
  const tags = Form.useWatch('tags', form)

  useEffect(() => {
    if (tags?.length && !form.getFieldValue('users')?.length) {
      form.setFieldValue('users', [{ name: 'required user' }])
    }
  }, [tags])

  const handleUserInChargeChange = (e: any, name: number) => {
    const users = form.getFieldValue('users')
    const updatedUsers = users.map((user: IUser, i: number) => ({
      ...user,
      user_in_charge: i === name
    }))
    form.setFieldsValue({ users: updatedUsers })
  }

  return (
    <Col span={24}>
      <Form.List name={nameForm}>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
              {fields.map(({ key, name, ...restField }) => (
                <Col key={key} xs={{ span: 24 }} xl={{ span: 12 }}>
                  <Card
                    key={key}
                    size="small"
                    title={`${t(title)}${name + 1}`}
                    extra={
                      data && data[key] ? (
                        <DeleteUserButton
                          key={key}
                          user={data && data[key]}
                          remove={() => remove(name)}
                        />
                      ) : (
                        <CloseOutlined
                          data-cy={`delete-user-${name}`}
                          onClick={() => {
                            removeFormItem(tags, fields.length, remove, name)
                          }}
                        />
                      )
                    }
                  >
                    <Row gutter={[8, 8]}>
                      {inputValues.map(item => (
                        <Col key={item} xs={{ span: 24 }} xl={{ span: 12 }}>
                          <TextInput
                            {...restField}
                            key={`${key}_${item}`}
                            name={[name, item]}
                            label={t(item.toUpperCase())}
                            rules={[
                              {
                                required: action === 'create',
                                message: t('requiredItem')
                              },
                              fieldRules[item]
                            ]}
                          />
                        </Col>
                      ))}
                      {nameForm === 'users' ? (
                        <>
                          <Col span={24}>
                            <Form.Item
                              name={[name, 'birthday']}
                              label={t('BIRTHDAY')}
                              rules={[
                                { required: true, message: t('requiredItem') }
                              ]}
                            >
                              <DatePicker
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD"
                                maxDate={dayjs().subtract(18, 'year')}
                                data-cy="birthday"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <PhonesForm name={[name, 'phones']} />
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name={[name, 'user_in_charge']}
                              valuePropName="checked"
                            >
                              <Checkbox
                                onChange={e =>
                                  handleUserInChargeChange(e, name)
                                }
                              >
                                {t('IS_IN_CHARGE')}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <SelectInput
                          inputName={[name, 'type']}
                          span={12}
                          label={'SIGN_AS'}
                          constant={'ORDER_SIGNATURE_TYPES'}
                          required
                        />
                      )}
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block>
                {t(`ADD${title}`)}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Col>
  )
}

export default FormUser

import { Row, Col, Form, TimePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import { Rule } from 'antd/lib/form'

interface TimeRangeInputProps {
  namePrefix: string
  label: string
  startPlaceholder: string
  endPlaceholder: string
  validateTimeDifference: (startTime: any, endTime: any) => boolean
  rules?: Rule[]
}

const TimeRangeInput = ({
  namePrefix,
  label,
  startPlaceholder,
  endPlaceholder,
  validateTimeDifference,
  rules = [{ required: true, message: 'Este campo é obrigatório' }],
  ...props
}: TimeRangeInputProps) => {
  const { t } = useTranslation()

  return {
    label,
    component: (
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            {...props}
            name={`${namePrefix}_start_at`}
            rules={[
              ...rules,
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || !getFieldValue(`${namePrefix}_end_at`)) {
                    return Promise.resolve()
                  }
                  if (
                    validateTimeDifference(
                      value,
                      getFieldValue(`${namePrefix}_end_at`)
                    )
                  ) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('FORMONBOARD.TIME_ERROR')))
                }
              })
            ]}
          >
            <TimePicker
              format="HH:mm:ss"
              style={{ width: '100%' }}
              placeholder={startPlaceholder}
              showNow={false}
              changeOnScroll
              needConfirm={false}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={`${namePrefix}_end_at`}
            rules={[
              ...rules,
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || !getFieldValue(`${namePrefix}_start_at`)) {
                    return Promise.resolve()
                  }
                  if (
                    validateTimeDifference(
                      getFieldValue(`${namePrefix}_start_at`),
                      value
                    )
                  ) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('FORMONBOARD.TIME_ERROR')))
                }
              })
            ]}
          >
            <TimePicker
              format="HH:mm:ss"
              style={{ width: '100%' }}
              placeholder={endPlaceholder}
              changeOnScroll
              needConfirm={false}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default TimeRangeInput

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useDebounce from './useDebounce'
import useSearchParams from '@hooks/useSearchParams'
import { SearchParams } from '@/types/SearchParams'

const onClearByName = (
  name: string,
  setValue: Dispatch<SetStateAction<string[] | []>>,
  params: SearchParams
) => {
  setValue([])
  params.removeItem(name)
}

const onChangeByName = (
  name: string,
  values: Array<string>,
  params: SearchParams,
  setValue: Dispatch<SetStateAction<string[] | []>>,
  onClear: () => void
) => {
  if (values?.length) {
    params.toogleItem(name, values.toString())
    setValue(values)
    return
  }
  onClear()
}

type UseTicketSelect = {
  name: string
  func: (search: string) => Promise<any>
}

const useTicketSelect = ({ name, func }: UseTicketSelect) => {
  const location = useLocation()
  const params = useSearchParams(true)
  const defaultValue = params.getItem(name)?.split(',')
  const [options, setOptions] = useState([])
  const [value, setValue] = useState<string[] | []>([])
  const [search, setSearch] = useState('')
  const { debouncedValue } = useDebounce(search)

  const onSearch = (search = '') => setSearch(search)
  const onFocus = () => onSearch('')
  const onClear = () => onClearByName(name, setValue, params)
  const onChange = (values: Array<string>) =>
    onChangeByName(name, values, params, setValue, onClear)

  useEffect(() => {
    //if (!initRef.current)
    func(search).then(e => setOptions(e))
    //initRef.current = false
  }, [debouncedValue])
  useEffect(() => {
    const items = params.getItem(name)
    if (items) {
      setValue(items.split(','))
      onSearch(items)
      return
    }
    setValue([])
  }, [location.search])

  return { onSearch, onClear, onFocus, onChange, options, defaultValue, value }
}

export default useTicketSelect

import { Button, Descriptions, Divider, Drawer, Form, DrawerProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { ModalContext } from '@contexts/ModalContext'
import { useGenericContext } from '@hooks/useGenericContext'
import verifyPutOrPost from '@helpers/verifyPutOrPost'
import { MessageContext } from '@contexts/MessageContext'
import { handleError } from '@helpers/handleError'
import { ProductItemTable } from '../Table'
import { api } from '@helpers/api'
import { DCS } from '@constants/dc'
import { IProductItem } from '@/types/IProduct'
import { IProduct } from '@/types/IOrder'

const getDCnameByUuid = (uuid: string) => {
  for (const i in DCS) {
    if (DCS[i as keyof typeof DCS] === uuid) return i
  }
}

const checkPrice = (price: string | number) =>
  typeof price === 'string' ? parseFloat(price).toFixed(2) : price.toFixed(2)

const handleProductItems = async (
  items: IProductItem[] | null,
  data: any,
  product: string,
  parent?: string
) => {
  if (items?.length) {
    items?.forEach(async item => {
      const { uuid, children, price, step, ...itemValues } = item

      const modifiedValues = {
        ...itemValues,
        parent: parent,
        step: step ? Math.round(step) : null,
        price: checkPrice(price)
      }
      const action = uuid
        ? async () =>
            api.put(`/product/${product}/item/${uuid}`, modifiedValues)
        : async () => api.post(`/product/${product}/item`, modifiedValues)

      await action().then(e => {
        handleProductItems(children, data, product, e.data.uuid)
      })
    })
  }
}

const updateProductValues = async (values: any, data: IProduct) => {
  const { items, uuid, ...productValues } = values
  await api.put(`/product/${data.uuid}`, productValues).then(async e => {
    await handleProductItems(items, data.items, data.uuid)
  })
}

const useChildrenDrawer = (data: IProduct | undefined) => {
  const { onClose } = useGenericContext(ModalContext)
  const messageApi = useGenericContext(MessageContext)
  const update = verifyPutOrPost(data)
  const action = data
    ? async (values: any) => await updateProductValues(values, data)
    : async (values: any) =>
        await api.post('/product', values).then(e => {
          return e
        })
  const onConfirm = async (values: any) => {
    try {
      await action(values).then(async () => {
        messageApi.success(update)
        onClose?.()
      })
    } catch (e) {
      handleError(e, messageApi, 'error')
    }
  }
  return onConfirm
}

interface ChildrenDrawerProps extends DrawerProps {
  data?: IProduct
}

const ProductChildrenDrawer = ({ data, ...props }: ChildrenDrawerProps) => {
  const onConfirm = useChildrenDrawer(data)
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const values = form.getFieldsValue()

  return (
    <Drawer
      {...props}
      width={'70%'}
      title={values ? values.name : null}
      extra={
        <Button onClick={() => onConfirm(values)} data-cy="send-values-button">
          {t('CONFIRM')}
        </Button>
      }
    >
      {values ? (
        <>
          <Descriptions
            bordered
            items={['name', 'dc'].map(e => ({
              key: e,
              label: t(e.toUpperCase()),
              children: e !== 'dc' ? values[e] : getDCnameByUuid(values[e])
            }))}
          />
          <Divider>{t('ITEMS')}</Divider>
          <ProductItemTable record={values} />
        </>
      ) : null}
    </Drawer>
  )
}

export default ProductChildrenDrawer

import { Button, Row, Col } from 'antd'
import Title from '@components/common/Title'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { OrderContext } from '@contexts/OrderContext'

const FormHead = () => {
  const { t } = useTranslation()
  const { numForm, price } = useGenericContext(OrderContext)
  return (
    <Row style={{ marginTop: '10px' }}>
      <Col xl={18} xs={16}>
        <Title
          data-cy="total-price"
          name={`${t('TOTAL_PRICE')}: R$${price.toFixed(2)}`}
        />
      </Col>
      <Col xl={{ span: 2, offset: 4 }} xs={4}>
        <Button
          children={t('SAVE_ORDER')}
          type="primary"
          form={numForm}
          key="submit"
          htmlType="submit"
        />
      </Col>
    </Row>
  )
}

export default FormHead
